/**
 * The navbar container
 */
import React from "react";
import { useWeb3React } from "@web3-react/core";
import classNames from "classnames";
import { Navbar } from "reactstrap";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { getProjectAttributes } from "@bkry/bowline-redux/projects";
import { TrunkatedAddress } from "@bkry/bowline-components/Utils";
import { FlashAlert } from "@bkry/bowline-components/FlashAlert";
import { BowlineWallet } from "@bkry/bowline-components/BowlineWallet";
import { ConnectWallet } from "@bkry/bowline-components/ConnectWallet";
import { env } from "@bkry/bowline-utils";
const { REACT_APP_PROJECT_ID } = env;
/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
const Navigationbar = (props, context) => {
  const { account } = useWeb3React();

  const projectData = useSelector((state) =>
    getProjectAttributes(state, REACT_APP_PROJECT_ID)
  );

  const alertMessage = useSelector((state) => state.notifications.message);
  const alertColor = useSelector((state) => state.notifications.color);
  const alertTimeout = useSelector((state) => state.notifications.timeout);
  const alertOrigin = useSelector((state) => state.notifications.origin);
  const alertLink = useSelector((state) => state.notifications.link);

  return (
    <div
      style={props.style}
      className={classNames("navigationbar ", {
        "navigationbar--sticky": props.isSticky,
      })}
    >
      <Navbar color="dark" expand="sm">
        {/* <NavbarToggler aria-label="navigation_toggle" onClick={toggle} /> */}
        <NavLink to="/" className="navbar-brand">
          {projectData?.title ? projectData.title : context.t("Home")}
        </NavLink>
        {!account ? (
          <ConnectWallet
            buttonColor="primary"
            buttonSize="sm"
            buttonId="nav-connect-wallet"
          />
        ) : (
          <span>
            <TrunkatedAddress address={account} />
            <BowlineWallet />
          </span>
        )}
      </Navbar>
      {alertMessage && (
        <FlashAlert
          color={alertColor || "success"}
          timeout={alertTimeout}
          origin={alertOrigin}
          link={alertLink}
        >
          {alertMessage}
        </FlashAlert>
      )}
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Navigationbar.contextTypes = {
  t: PropTypes.func,
};

Navigationbar.propTypes = {};

Navigationbar.defaultProps = {};

export default Navigationbar;
