/**
 *  The Private Route container
 */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { useSelector } from "react-redux";
import { projectAttributes } from "@bkry/bowline-redux/projects";
import PropTypes from "prop-types";
import { getWalletAttributes } from "@bkry/bowline-redux/wallets";

/**
 * Stateless component to check user authentication before redirecting to a page
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { account } = useWeb3React();
  const project = useSelector(projectAttributes);
  const wallet = useSelector((state) => getWalletAttributes(state, account));
  let authorized = false;

  if (project && project.access_mode) {
    switch (project.access_mode) {
      case "anyone":
        authorized = true;
        break;
      case "connected_wallets":
        if (account) {
          authorized = true;
        }
        break;
      case "signed_in_wallets":
        if (wallet) {
          authorized = true;
        }
        break;
      case "holder_wallets":
        if (wallet && wallet.current_wallet_is_holder) {
          authorized = true;
        }
        break;
    }
  }

  return project ? (
    <Route
      {...rest}
      render={(props) => {
        return authorized ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/403",
              state: {
                accessMode: project?.access_mode,
                accessErrorCode: 403,
                path: rest.path,
              },
            }}
          />
        );
      }}
    />
  ) : null;
};

/**  define proptype for the 'translation' function  */
PrivateRoute.propTypes = {
  component: PropTypes.func,
};

/** export the redux connected component and add a frontload call */
export default PrivateRoute;
