import React from "react";
import PropTypes from "prop-types";

/**
 * PrettyType component to translate the type of an event
 */
const PrettyType = (props, context) => {
  const { type } = props;

  const translations = {
    community_event: context.t("community event"),
    partner_event: context.t("partner event"),
    conference: context.t("conference"),
    irl_meetup: context.t("irl meetup"),
    irl_party: context.t("irl party"),
    virtual_meetup: context.t("virtual meetup"),
    discord_event: context.t("discord event"),
    ask_me_anything: context.t("ask me anything"),
  };
  return <>{translations[type] ? translations[type] : type}</>;
};

/**  define proptypes for PrettyType  */
PrettyType.propTypes = {
  type: PropTypes.string.isRequired,
};

/**  define defaultProps for PrettyType  */
PrettyType.defaulProps = {};

/**  define proptype for the 'translation' function  */
PrettyType.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default PrettyType;
