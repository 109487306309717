import { initializeConnector } from "@web3-react/core";
import { LedgerWallet } from "./lib/ledgerWallet";
import { env } from "@bkry/bowline-utils";
import { URLS } from "./chains";
const {
  REACT_APP_MAINNET,
} = env;

export const [ledgerWallet, hooks] = initializeConnector(
  (actions) =>
    new LedgerWallet({
      actions,
      options: {
        rpc: {
          1: URLS[1][0],
          5: URLS[5][0],
          137: URLS[137][0],
        },
        chainId: REACT_APP_MAINNET ? 1 : 5,
        chainHex: REACT_APP_MAINNET ? "0x1" : "0x5",
      },
    })
);
