import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Timer = (props) => {
  const { startSeconds } = props;
  const [seconds, setSeconds] = useState(startSeconds);
  useEffect(() => {
    let timerInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  });

  return (
    <div>
      <h4 className="text-primary">{seconds < 10 ? `0${seconds}` : seconds}</h4>
    </div>
  );
};

Timer.propTypes = {
  startSeconds: PropTypes.number,
};

Timer.defaultProps = {
  startSeconds: 0,
};

export default Timer;
