import React from "react";
import PropTypes from "prop-types";

/**
 * Table container used as app default landing page
 */
const TrunkatedAddress = (props) => {
  const { address } = props;

  const firstAddressDigits = address?.substring(0, 6);
  const lastAddressDigits = address?.slice(-4);

  return (
    <>
      {firstAddressDigits}...{lastAddressDigits}
    </>
  );
};

/**  define proptypes for TrunkatedAddress  */
TrunkatedAddress.propTypes = {
  address: PropTypes.string.isRequired,
};

/**  define defaultProps for TrunkatedAddress  */
TrunkatedAddress.defaulProps = {};

/** export the component as redux connected component */
export default TrunkatedAddress;
