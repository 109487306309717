/**
 * The Home container
 */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import { getTokenAttributes } from "@bkry/bowline-redux/tokens";
import { push } from "connected-react-router";
import { useWeb3React } from "@web3-react/core";
import { Card, CardBody, CardImg, CardTitle, Badge } from "reactstrap";
import { StakingInfo } from "@bkry/bowline-components/Staking";

/**
 * Home container used as app default landing page
 */
const TokenCard = (props) => {
  const {
    tokenId,
    stakeBtnClicked,
    setStakeBtnClicked,
    onSubmit,
    setSelectTokensMode,
    selectTokensMode,
    setSelectedTokens,
    selectedTokens,
    addSelectedToken,
    removeSelectedToken,
    dashboardView,
    stakingContract,
    handleCardClickOverride,
  } = props;
  const { account } = useWeb3React();
  const tokenData = useSelector((state) => getTokenAttributes(state, tokenId));
  const { name, thumbnail } = tokenData;
  const dispatch = useDispatch();
  let fallbackImage;
  if (!thumbnail && tokenData?.metadata) {
    try {
      const metadata = JSON.parse(tokenData.metadata);
      fallbackImage = metadata?.image;
    } catch {
      console.log("Failed to parse Metadat");
    }
  }
  const isStaked =
    !!tokenData?.utility_contract_address &&
    !!stakingContract &&
    tokenData?.utility_contract_address.toLowerCase() ===
      stakingContract.toLowerCase();

  const handleCardClick = () => {
    if (handleCardClickOverride) {
      handleCardClickOverride();
    } else {
      dispatch(
        push(`/tokens/${tokenData?.contract_address}/${tokenData?.identifier}`)
      );
    }
  };

  return (
    <Card className="mb-3">
      {thumbnail || fallbackImage ? (
        <>
          <CardImg
            top
            width="100%"
            src={thumbnail || fallbackImage}
            alt={name}
            onClick={handleCardClick}
          />
        </>
      ) : (
        <div
          className="bg-primary ratio ratio-1x1 card-img-top"
          onClick={handleCardClick}
        />
      )}
      {isStaked && (
        <Badge className="position-absolute top-0 end-0 p-2 bg-success rounded-pill m-2 fw-bold">
          staked
        </Badge>
      )}
      <CardBody>
        <CardTitle onClick={handleCardClick}>
          {/* <Link
            to={`/tokens/${tokenData?.contract_address}/${tokenData?.identifier}`}
            className="text-decoration-none text-body"
          > */}
          <b>{name}</b>
          {/* </Link> */}
        </CardTitle>
        {account &&
          account == tokenData?.owner_address &&
          dashboardView &&
          // tokenData?.contract_address.toLowerCase() !==
          //   "0x37297193da5988b5db37a18f78468f368aeff7b4" &&
          stakingContract && (
            <StakingInfo
              contractAddress={tokenData?.contract_address}
              tokenId={tokenData?.identifier}
              isStaked={isStaked}
              stakeBtnClicked={stakeBtnClicked}
              setStakeBtnClicked={setStakeBtnClicked}
              onSubmit={onSubmit}
              setSelectTokensMode={setSelectTokensMode}
              selectTokensMode={selectTokensMode}
              selectedTokens={selectedTokens}
              setSelectedTokens={setSelectedTokens}
              removeSelectedToken={removeSelectedToken}
              addSelectedToken={addSelectedToken}
              stakingContract={stakingContract}
            />
          )}
      </CardBody>
    </Card>
  );
};

TokenCard.propTypes = {
  tokenId: PropTypes.string.isRequired,
  account: PropTypes.string,
  stakeBtnClicked: PropTypes.bool,
  setStakeBtnClicked: PropTypes.func,
  onSubmit: PropTypes.func,
  setSelectTokensMode: PropTypes.func,
  selectTokensMode: PropTypes.bool,
  setSelectedTokens: PropTypes.func,
  selectedTokens: PropTypes.array,
  addSelectedToken: PropTypes.func,
  removeSelectedToken: PropTypes.func,
  dashboardView: PropTypes.bool,
  stakingContract: PropTypes.string,
  handleCardClickOverride: PropTypes.func,
};

/**  define proptype for the 'translation' function  */
TokenCard.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default TokenCard;
