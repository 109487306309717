import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import LazyLoad from "react-lazyload";
import classNames from "classnames";

const Medium = (props) => {
  const { mediumData, className, skipLazyLoading, altTitle } = props;

  const renderVideoPlayer = () => (
    <ReactPlayer
      width="100%"
      height="auto"
      controls={true}
      url={mediumData?.url}
      className={classNames(className)}
    />
  );

  const renderPicture = () => (
    <picture className={classNames("mw-100 mh-100 d-flex", className)}>
      {mediumData?.webp_url && (
        <source srcSet={mediumData.webp_url} type="image/webp" />
      )}
      {mediumData?.url && (
        <source srcSet={mediumData.url} type={mediumData?.content_type} />
      )}
      <img
        srcSet="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        className="img-fluid"
        loading={skipLazyLoading ? "eager" : "lazy"}
        style={{ objectFit: "contain" }}
        height={mediumData?.metadata?.height}
        width={mediumData?.metadata?.width}
        alt={altTitle}
      />
    </picture>
  );

  return (
    <>
      {mediumData?.is_image && renderPicture()}
      {mediumData?.is_video && (
        <>
          {skipLazyLoading ? (
            renderVideoPlayer()
          ) : (
            <LazyLoad offset={200}>{renderVideoPlayer()}</LazyLoad>
          )}
        </>
      )}
    </>
  );
};

/**  define proptype for the 'translation' function  */
Medium.contextTypes = {
  t: PropTypes.func,
};

Medium.propTypes = {
  mediumData: PropTypes.object.isRequired,
  className: PropTypes.string,
  skipLazyLoading: PropTypes.bool,
  altTitle: PropTypes.string,
};

Medium.defaultProps = {
  skipLazyLoading: false,
};

export default Medium;
