/**
 * The SlimFooter container
 */
import { getProjectAttributes } from "@bkry/bowline-redux/projects";
import { env } from "@bkry/bowline-utils";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

const { REACT_APP_PROJECT_ID } = env;

/**
 * SlimFooter component used as app default landing page
 *
 */

const SlimFooter = (props, context) => {
  const projectData = useSelector((state) =>
    getProjectAttributes(state, REACT_APP_PROJECT_ID)
  );
  return (
    <div className="text-center py-3">
      <p className="mb-0">{projectData?.title && projectData.title}</p>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
SlimFooter.contextTypes = {
  t: PropTypes.func,
};

SlimFooter.propTypes = {
  title: PropTypes.string,
  backTarget: PropTypes.string,
};

SlimFooter.defaultProps = {
  backTarget: null,
};

/** export the component as redux connected component */
export default withRouter(SlimFooter);
