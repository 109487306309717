/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";
import Cookies from "universal-cookie";
const cookieHandler = new Cookies();

export const walletsSlice = createSlice({
  name: "wallets",
  initialState: { ...defaultInitialState, keepWalletConnection: false, stored: true },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    siwe: (state) => {
      state.siweRunning = true;
      state.siweSuccess = false;
      state.siweError = null;
      state.currentWalletId = null;
    },
    siweSuccess: (state, action) => {
      state.siweRunning = false;
      state.siweSuccess = true;
      state.currentWalletId = action.payload.id;
      state.dictionary[action.payload.id] = action.payload;
      state.siweError = null;
    },
    siweFail: (state, action) => {
      state.siweRunning = false;
      state.siweSuccess = false;
      state.currentWalletId = null;
      state.siweError = action.payload.mess;
      cookieHandler.remove("wallet-token", { sameSite: true, path: "/" });
    },
    project: (state) => {
      state.projectRunning = true;
      state.projectSuccess = false;
      state.projectError = null;
    },
    projectSuccess: (state, action) => {
      state.projectRunning = false;
      state.projectSuccess = true;
      state.projectError = null;
    },
    projectFail: (state, action) => {
      state.projectRunning = false;
      state.projectSuccess = false;
      state.projectError = action?.payload?.mess;
    },
    siweLogout: (state, action) => {
      state.siweRunning = false;
      state.siweSuccess = false;
      state.siweError = null;
      state.currentWalletId = null;
      state.keepWalletConnection = false;
      cookieHandler.remove("wallet-token", {
        sameSite: true,
        path: "/",
      });
    },
    prepareSiwe: (state) => {
      state.prepareSiweRunning = true;
      state.prepareSiweSuccess = false;
      state.prepareSiweError = null;
      state.currentWalletId = null;
      cookieHandler.remove("wallet-token", { sameSite: true, path: "/" });
    },
    prepareSiweSuccess: (state, action) => {
      state.keepWalletConnection = true;
      state.prepareSiweRunning = false;
      state.prepareSiweSuccess = true;
      state.prepareSiweError = null;
      cookieHandler.set("wallet-token", action.payload.signature, {
        sameSite: true,
        path: "/",
      });
    },
    setKeepWalletConnection: (state, action) => {
      state.keepWalletConnection = action.payload;
    },
    prepareSiweFail: (state, action) => {
      state.prepareSiweRunning = false;
      state.prepareSiweSuccess = false;
      state.currentWalletId = null;
      state.prepareSiweError = action.payload.mess;
      cookieHandler.remove("wallet-token", { sameSite: true, path: "/" });
    },
    addToDictionary: defaultAddToDictionary,
  },
});
export const {
  siwe: walletSiwe,
  siweLogout,
  prepareSiwe: prepareWalletSiwe,
  show: showWallet,
  list: listWallets,
  create: createWallet,
  update: updateWallet,
  project: registerProject,
  setKeepWalletConnection,
} = walletsSlice.actions;

export const actions = walletsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const wallets = (state) =>
  state.wallets.idsList.map((id) => state.wallets.dictionary[id]);
export const wallet = (state) =>
  state.wallets.showId && state.wallets.dictionary[state.wallets.showId];
export const walletAttributes = (state) => wallet(state)?.attributes;
export const walletRelationships = (state) => wallet(state)?.relationships;
export const walletId = (state) => state.wallets.showId;

export const getWallet = createSelector(
  (state) => state.wallets.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getWalletAttributes = createSelector(
  (state) => state.wallets.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getWalletRelationships = createSelector(
  (state) => state.wallets.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getWalletByAddress = createSelector(
  (state) => state.wallets.dictionary,
  (_, address) => address,
  (dictionary, address) => {
    let getWalletByAddress =
      dictionary &&
      address &&
      Object.keys(dictionary).find(
        (key) => dictionary[key].attributes.address === address
      );
    return getWalletByAddress;
  }
);

export const getWallets = createSelector(
  (state) => state.wallets.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export const getWalletProfileValuesResume = createSelector(
  (state) => state.wallets.dictionary,
  (state) => state.walletProfileValues.dictionary,
  (state) => state.walletProfileInputs.dictionary,
  (_, id) => id,
  (dictionary, valuesDictionary, inputsDictionary, id) => {
    let wallet = dictionary && dictionary[id];
    if (wallet) {
      let valuesIds =
        wallet.relationships?.wallet_profile_values?.data?.map((i) => i.id) ||
        [];
      let values = valuesIds.map((id) => valuesDictionary[id]);
      console.log(values);
      let results = Object.keys(inputsDictionary).map((inputkey) => {
        let input = inputsDictionary[inputkey];
        console.log(input);
        const value = values.find(
          (entry) =>
            entry.relationships.wallet_profile_input.data.id === input.id
        );
        return {
          id: input.id,
          name: input.attributes.input_name,
          value: value?.attributes.value,
          type: input.attributes.input_type,
          order: input.attributes.input_order,
        };
      });
      return results;
    }
    return [];
  }
);
export default walletsSlice.reducer;
