/**
 * The BigFooter container
 */
import { getProjectAttributes } from "@bkry/bowline-redux/projects";
import { env } from "@bkry/bowline-utils";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

const { REACT_APP_PROJECT_ID } = env;

/**
 * BigFooter component used as app default landing page
 *
 */

const BigFooter = (props, context) => {
  const projectData = useSelector((state) =>
    getProjectAttributes(state, REACT_APP_PROJECT_ID)
  );
  return (
    <div className="mt-5 py-3 text-center">
      <div className="h1 mb-0">{projectData?.title && projectData.title}</div>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
BigFooter.contextTypes = {
  t: PropTypes.func,
};

BigFooter.propTypes = {
  title: PropTypes.string.isRequired,
  backTarget: PropTypes.string,
};

BigFooter.defaultProps = {
  backTarget: null,
};

/** export the component as redux connected component */
export default withRouter(BigFooter);
