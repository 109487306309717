/**
 * The HeaderNavItems container
 */
import { getNavigationItems } from "@bkry/bowline-redux/navigationItems";
import { getProjectRelationships } from "@bkry/bowline-redux/projects";
import { env } from "@bkry/bowline-utils";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Nav, NavItem, NavLink as NavbarLink } from "reactstrap";

const { REACT_APP_PROJECT_ID } = env;

/**
 * HeaderNavItems component used as app default landing page
 *
 */

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */

const HeaderNavItems = (props) => {
  const { closeMobileNav } = props;
  const projectRelationships = useSelector((state) =>
    getProjectRelationships(state, REACT_APP_PROJECT_ID)
  );

  const navigationItems = useSelector((state) =>
    getNavigationItems(
      state,
      projectRelationships?.navigation_items?.data?.map((item) => item.id)
    )
  );

  const handleOnClick = () => {
    closeMobileNav ? closeMobileNav() : null;
  };

  return (
    <Nav className="me-auto" navbar>
      {navigationItems?.map((item) =>
        item?.attributes?.content_type === "page" ? (
          <NavItem key={item?.id}>
            <NavLink
              className="nav-link"
              to={`/${item?.attributes?.url}`}
              onClick={handleOnClick}
            >
              {item?.attributes?.title}
            </NavLink>
          </NavItem>
        ) : (
          <NavItem key={item?.id}>
            <NavbarLink href={item?.attributes?.url} onClick={handleOnClick}>
              {item?.attributes?.title}
            </NavbarLink>
          </NavItem>
        )
      )}
    </Nav>
  );
};

/**  define proptype for the 'translation' function  */
HeaderNavItems.contextTypes = {
  t: PropTypes.func,
};

HeaderNavItems.propTypes = {
  closeMobileNav: PropTypes.func,
};

/** export the component as redux connected component */
export default HeaderNavItems;
