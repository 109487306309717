/**
 * The HeaderLayout container
 */
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getProjectAttributes } from "@bkry/bowline-redux/projects";
import {
  TopSlim,
  TopUnsticky,
  CenteredLogoWithBurger,
  LogoAndWalletHeader,
  SideBar,
} from "@bkry/bowline-components/Headers";
import { Navbar } from "@bkry/bowline-components/Navbar";

import { env } from "@bkry/bowline-utils";
const { REACT_APP_PROJECT_ID } = env;

/**
 * HeaderLayout component used as app default landing page
 *
 */

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */

const HeaderLayout = () => {
  const projectData = useSelector((state) =>
    getProjectAttributes(state, REACT_APP_PROJECT_ID)
  );

  return (
    <>
      {projectData?.header_layout === "only_logo_and_wallet" ? (
        <LogoAndWalletHeader />
      ) : projectData?.header_layout === "centered_logo_with_burger" ? (
        <CenteredLogoWithBurger />
      ) : projectData?.header_layout === "sidebar" ? (
        <SideBar />
      ) : projectData?.header_layout === "top_slim" ? (
        <TopSlim />
      ) : projectData?.header_layout === "top_unsticky" ? (
        <TopUnsticky />
      ) : projectData?.header_layout === "no_header" ? (
        ""
      ) : (
        <Navbar />
      )}
    </>
  );
};

/**  define proptype for the 'translation' function  */
HeaderLayout.contextTypes = {
  t: PropTypes.func,
};

HeaderLayout.propTypes = {};

/** export the component as redux connected component */
export default HeaderLayout;
