/**
 * The FlashAlert component
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";
import classNames from "classnames";
import { isServer } from "@bkry/bowline-redux";

/**
 * Enhancer for reactstrap Alert
 * - adds timeout property (in seconds) to define the timeout to close the Alert
 */
const FlashAlert = (props, context) => {
  const { children, color, className, link } = props;
  const showReload = link === "reload";
  const [timeout, setTimeout] = useState(false);
  const [visible, setVisible] = useState(true);

  const onDismiss = () => {
    setVisible(false);
  };

  /**
   * Sets the timeout on component mount
   */
  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        onDismiss();
      }, timeout * 1000);
    }
  }, [timeout]);
  /**
   * Dismisses the flash alert by changing its visibility
   */

  /**
   * click action
   */
  const onClick = () => {
    const reload = link === "reload";
    if (reload) {
      setVisible(false);
      if (!isServer && window) window.location.reload();
    }
  };

  /**
   * Renders the Alert component
   */
  return (
    <Alert
      color={color}
      isOpen={visible}
      toggle={onDismiss}
      onClick={onClick}
      className={classNames({ "cursor-pointer": showReload }, className)}
    >
      {children}
      {showReload && (
        <span>
          :&nbsp;
          {context.t(
            "New content is available. Please click here to refresh the page and avoid inconsistencies."
          )}
        </span>
      )}
    </Alert>
  );
};

FlashAlert.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  link: PropTypes.string,
};

/**  define proptype for the 'translation' function  */
FlashAlert.contextTypes = {
  t: PropTypes.func,
};

export default FlashAlert;
