/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";

export const signRequestSlice = createSlice({
  name: "signRequests",
  initialState: {
    dictionary: {}, // holds only pending sign requests
    list: [], // list of success/fail sign requests
  },
  reducers: {
    addToDictionary: (state, action) => {
      state.dictionary[action.payload.id] = {
        ...action.payload,
        createdAt: new Date(),
        signRequestStatus: "pending",
      };
    },
    removeFromDictionary: (state, action) => {
      delete state.dictionary[action.payload];
    },
    signRequestSuccess: (state, action) => {
      state.list.push({
        ...state.dictionary[action.payload],
        signRequestStatus: "success",
      });
      delete state.dictionary[action.payload];
    },
    signRequestFail: (state, action) => {
      state.list.push({
        ...state.dictionary[action.payload],
        signRequestStatus: "fail",
      });
      delete state.dictionary[action.payload];
    },
  },
});
export const {
  addToDictionary,
  removeFromDictionary,
  signRequestSuccess,
  signRequestFail,
} = signRequestSlice.actions;

export const actions = signRequestSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const signRequests = (state) =>
  Object.keys(state.signRequests.dictionary).map(
    (srk) => state.signRequests.dictionary[srk]
  );
export const signRequestsCount = (state) =>
  Object.keys(state.signRequests.dictionary).length;

export const getSignRequest = createSelector(
  (state) => state.signRequests.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export default signRequestSlice.reducer;
