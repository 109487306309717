/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    ...defaultInitialState,
    loadingDashboard: false,
    loadedDashboard: false,
    dashboardError: null,
    dashboard: null,
    dashboardId: null,
    dashboardDictionary: {},
  },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    updateErrorReset: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
    listErrorReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createErrorReset: (state, action) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
    showErrorReset: (state, action) => {
      state.showing = false;
      state.showed = false;
      state.showError = null;
      state.unlockError = null;
      state.unlockSuccess = false;
    },
    dashboard: (state, action) => {
      state.loadingDashboard = true;
      state.loadedDashboard = false;
      state.dashboardError = null;
      state.dashboard =
        state.dashboardDictionary["dash-" + action.payload.project_id] || {};
      state.dashboardId = action.payload
        ? "dash-" + action.payload.project_id
        : null;
    },
    dashboardSuccess: (state, action) => {
      state.loadingDashboard = false;
      state.loadedDashboard = true;
      state.dashboard = action.payload;
      state.dashboardId = action.payload.id;
      state.dashboardDictionary[action.payload.id] = action.payload;
    },
    dashboardFail: (state, action) => {
      state.loadingDashboard = false;
      state.loadedDashboard = false;
      state.dashboardError = action?.payload?.message;
    },
  },
});
export const {
  show: showProject,
  update: updateProject,
  list: listProjects,
  create: createProject,
  updateErrorReset: updateProjectErrorReset,
  listErrorReset: listProjectErrorReset,
  createErrorReset: createProjectErrorReset,
  showErrorReset: showProjectErrorReset,
  dashboard: loadProjectDashboard,
} = projectsSlice.actions;

export const actions = projectsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const projects = (state) =>
  state.projects.idsList.map((id) => state.projects.dictionary[id]);
export const project = (state) =>
  state.projects.showId && state.projects.dictionary[state.projects.showId];
export const projectAttributes = (state) => project(state)?.attributes;
export const projectRelationships = (state) => project(state)?.relationships;
export const projectId = (state) => state.projects.showId;

export const getProject = createSelector(
  (state) => state.projects.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getProjectAttributes = createSelector(
  (state) => state.projects.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getProjectRelationships = createSelector(
  (state) => state.projects.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);
export const dashboard = (state) =>
  state.projects.dashboardId &&
  state.projects.dashboardDictionary[state.projects.dashboardId];
export const dashboardAttributes = (state) => dashboard(state)?.attributes;
export const dashboardRelationships = (state) =>
  dashboard(state)?.relationships;
export const dashboardId = (state) => state.projects.dashboardId;

export const getDashboard = createSelector(
  (state) => state.projects.dashboardDictionary,
  (_, id) => id,
  (dashboardDictionary, id) => {
    return id && dashboardDictionary && dashboardDictionary[id];
  }
);

export const getDashboardAttributes = createSelector(
  (state) => state.projects.dashboardDictionary,
  (_, id) => id,
  (dashboardDictionary, id) => {
    return id && dashboardDictionary && dashboardDictionary[id]?.attributes;
  }
);

export const getDashboardRelationships = createSelector(
  (state) => state.projects.dashboardDictionary,
  (_, id) => id,
  (dashboardDictionary, id) => {
    return id && dashboardDictionary && dashboardDictionary[id]?.relationships;
  }
);

export const getProjects = createSelector(
  (state) => state.projects.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export const getProjectsAttributes = createSelector(
  (state) => state.projects.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return (
      ids &&
      dictionary &&
      ids.map((id) => ({ id: id, ...dictionary[id]?.attributes }))
    );
  }
);

export default projectsSlice.reducer;
