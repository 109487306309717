/**
 * The BowlineWallet component
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";

/**
 * Stateless react component to render the BowlineWallet component
 * @param {Object} props the props passed to the component.
 */
const Balance = function () {
  const { account, provider, chainId } = useWeb3React();

  const [balance, setBalance] = useState();
  React.useEffect(() => {
    if (!!account && !!provider) {
      let stale = false;

      provider
        .getBalance(account)
        .then((balance) => {
          if (!stale) {
            setBalance(balance.toString());
          }
        })
        .catch(() => {
          if (!stale) {
            setBalance(null);
          }
        });

      return () => {
        stale = true;
        setBalance(undefined);
      };
    }
  }, [account, provider, chainId]); // ensures refresh if referential identity of provider doesn't change across chainIds

  return (
    <>
      <span>
        {balance === null
          ? "Error"
          : balance
          ? `Ξ${ethers.utils.formatEther(balance)}`
          : ""}
      </span>
    </>
  );
};

/**  define proptype for the 'translation' function  */
Balance.contextTypes = {
  t: PropTypes.func,
};

Balance.propTypes = {};

export default Balance;
