import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import TokenProperty from "./utils/TokenProperty";

/**
 * Table container used as app default landing page
 */
const TokenProperties = (props) => {
  const { traits } = props;

  return (
    <Row>
      {traits.map((trait, i) => (
        <Col md={4} key={`trait-${i}`} className="mb-3">
          <TokenProperty traitId={trait.id} />
        </Col>
      ))}
    </Row>
  );
};

/**  define proptypes for TokenProperties  */
TokenProperties.propTypes = {
  traits: PropTypes.array.isRequired,
};

/**  define defaultProps for TokenProperties  */
TokenProperties.defaulProps = {};

/**  define proptype for the 'translation' function  */
TokenProperties.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default TokenProperties;
