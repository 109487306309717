/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";

export const transactionRequestSlice = createSlice({
  name: "transactionRequests",
  initialState: {
    dictionary: {},
    list: [],
  },
  reducers: {
    addToDictionary: (state, action) => {
      state.dictionary[action.payload.id] = {
        ...action.payload,
        createdAt: new Date(),
      };
    },
    removeFromDictionary: (state, action) => {
      delete state.dictionary[action.payload];
    },
  },
});
export const { addToDictionary, removeFromDictionary } =
  transactionRequestSlice.actions;

export const actions = transactionRequestSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const transactionRequests = (state) =>
  Object.keys(state.transactionRequests.dictionary).map(
    (srk) => state.transactionRequests.dictionary[srk]
  );
export const transactionRequestsCount = (state) =>
  Object.keys(state.transactionRequests.dictionary).length;

export const getTransactionRequest = createSelector(
  (state) => state.transactionRequests.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export default transactionRequestSlice.reducer;
