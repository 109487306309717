/**
 * The Home container
 */
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { TokenCard } from "@bkry/bowline-components/Token";

/**
 * Home container used as app default landing page
 */
const TokenList = ({
  stakeBtnClicked,
  setStakeBtnClicked,
  onSubmit,
  setSelectTokensMode,
  selectTokensMode,
  setSelectedTokens,
  selectedTokens,
  removeSelectedToken,
  addSelectedToken,
  dashboardView,
  stakingContract,
}) => {
  const tokenIds = useSelector((state) => state.tokens.idsList);
  return (
    <div className="nft-list">
      <Row className="gy-3 mb-3">
        {tokenIds.map((tokenId) => (
          <Col key={`token-${tokenId}`} sm={6} md={4} lg={3}>
            <TokenCard
              tokenId={tokenId}
              stakeBtnClicked={stakeBtnClicked}
              setStakeBtnClicked={setStakeBtnClicked}
              onSubmit={onSubmit}
              setSelectTokensMode={setSelectTokensMode}
              selectTokensMode={selectTokensMode}
              setSelectedTokens={setSelectedTokens}
              selectedTokens={selectedTokens}
              removeSelectedToken={removeSelectedToken}
              addSelectedToken={addSelectedToken}
              dashboardView={dashboardView}
              stakingContract={stakingContract}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

TokenList.propTypes = {
  setStakeBtnClicked: PropTypes.func,
  stakeBtnClicked: PropTypes.bool,
  onSubmit: PropTypes.func,
  setSelectTokensMode: PropTypes.func,
  selectTokensMode: PropTypes.bool,
  setSelectedTokens: PropTypes.func,
  selectedTokens: PropTypes.array,
  removeSelectedToken: PropTypes.func,
  addSelectedToken: PropTypes.func,
  dashboardView: PropTypes.bool,
  stakingContract: PropTypes.string,
};

/**  define proptype for the 'translation' function  */
TokenList.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default TokenList;
