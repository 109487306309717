/**
 * The WalletProfileInputsForm component
 */
import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Input,
  Label,
  // Container,
  Row,
  Col,
  ModalHeader,
  // FormText,
  // Collapse,
  // ModalHeader,
  // ModalFooter,
} from "reactstrap";
// import { useWeb3React } from "@web3-react/core";
import { updateWallet } from "@bkry/bowline-redux/wallets";
import { getProjectRelationships } from "@bkry/bowline-redux/projects";

import { getWalletProfileInputs } from "@bkry/bowline-redux/walletProfileInputs";
import { env } from "@bkry/bowline-utils";
const { REACT_APP_PROJECT_ID } = env;
// import { actions as walletActions } from "@bkry/bowline-redux";
/**
 * Stateless react component to render the WalletProfileInputsForm component
 * @param {Object} props the props passed to the component.
 */
const WalletProfileInputsForm = ({ dismiss, open }, context) => {
  // const { connector } = useWeb3React();
  const dispatch = useDispatch();

  const signedInWithEthereum = useSelector(
    (state) => state.wallets.siweSuccess
  );

  const projectRelationships = useSelector((state) =>
    getProjectRelationships(state, REACT_APP_PROJECT_ID)
  );

  const walletProfileInputs = useSelector((state) =>
    getWalletProfileInputs(
      state,
      projectRelationships?.wallet_profile_inputs?.data?.map((wpi) => wpi.id) ||
        []
    )
  );

  const sortedWalletProfileInputs = walletProfileInputs.map
    ? walletProfileInputs.sort((a, b) => {
        return a.attributes.input_order - b.attributes.input_order;
      })
    : [];

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const currentWalletId = useSelector((state) => state.wallets.currentWalletId);

  const handleWalletProfileInputs = (data) => {
    const inputValues = Object.keys(data).map((key) => ({
      value: data[key],
      wallet_profile_input_id: key,
      project_id: REACT_APP_PROJECT_ID,
    }));
    dispatch(
      updateWallet({
        projectId: REACT_APP_PROJECT_ID,
        address: currentWalletId,
        wallet: {
          wallet_profile_values_attributes: inputValues,
        },
        callbackFnc: () => dismiss(),
      })
    );
  };

  return (
    <>
      <Modal
        isOpen={open && signedInWithEthereum}
        className="onboardinginputs"
        fullscreen="md"
        size="xl"
        centered
        scrollable
      >
        <ModalHeader>{context.t("please complete your profile")}</ModalHeader>
        <ModalBody className="pt-md-5 pb-md-5">
          <form onSubmit={handleSubmit(handleWalletProfileInputs)}>
            <Row className="mb-3">
              {sortedWalletProfileInputs?.map &&
                sortedWalletProfileInputs.map((unlockInput) => (
                  <Col
                    sm={{ size: unlockInput.attributes?.input_size * 2 }}
                    xl={{ size: unlockInput.attributes?.input_size }}
                    key={unlockInput?.id}
                    className="mb-4"
                  >
                    <FormGroup className="mb-3">
                      <Label for={unlockInput.id}>
                        {unlockInput.attributes?.input_name}
                        {unlockInput?.attributes?.required && <> *</>}
                      </Label>
                      <Input
                        id={unlockInput.id}
                        type={unlockInput.attributes?.input_type}
                        invalid={errors[unlockInput?.id] ? true : false}
                        required={unlockInput?.attributes?.required}
                        {...register(unlockInput?.id, {
                          required: unlockInput?.attributes?.required
                            ? context.t("This field is required")
                            : false,
                          pattern:
                            unlockInput?.attributes?.input_type === "email"
                              ? {
                                  value: /\S+@\S+\.\S+/,
                                  message: context.t(
                                    "Entered value does not match email format"
                                  ),
                                }
                              : undefined,
                        })}
                        onChange={(evt) => {
                          setValue(unlockInput?.id, evt.target.value, {
                            shouldValidate: true,
                          });
                        }}
                      />
                      {errors[unlockInput?.id] && (
                        <span role="alert" className="text-danger small mt-2">
                          {errors[unlockInput?.id].message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                ))}
            </Row>
            <div>
              <small>{context.t("* means required")}</small>
            </div>
            <Row className="mb-4">
              <Col md={{ size: 3, offset: 9 }}>
                <Button
                  className="profileform-submit"
                  color="primary"
                  block
                  size="lg"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {context.t("Submit")}
                </Button>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

/**  define proptype for the 'translation' function  */
WalletProfileInputsForm.contextTypes = {
  t: PropTypes.func,
};

WalletProfileInputsForm.propTypes = {
  dismiss: PropTypes.func,
  open: PropTypes.bool,
};

export default WalletProfileInputsForm;
