import React from "react";
import PropTypes from "prop-types";
import { useWeb3React } from "@web3-react/core";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { prepareWalletSiwe, walletSiwe } from "@bkry/bowline-redux/wallets";

const SignInWithEthereumButton = (props, context) => {
  const { className, label, color, size, block, outline, onSuccess } = props;
  const { account, provider, connector } = useWeb3React();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      prepareWalletSiwe({
        provider,
        connector,
        account,
        callbackSuccess: ({ nonce, nonceIssuedAt }) => {
          dispatch(
            walletSiwe({
              account,
              nonce,
              nonceIssuedAt,
              callbackSuccess: () => {
                onSuccess ? onSuccess() : null;
              },
            })
          );
        },
      })
    );
  };

  return (
    <Button
      onClick={handleClick}
      className={className}
      color={color}
      size={size}
      block={block}
      outline={outline}
    >
      {label ? label : context.t("Sign In with Ethereum")}
    </Button>
  );
};

/**  define proptype for the 'translation' function  */
SignInWithEthereumButton.contextTypes = {
  t: PropTypes.func,
};

SignInWithEthereumButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  block: PropTypes.bool,
  outline: PropTypes.bool,
  onSuccess: PropTypes.func,
};

SignInWithEthereumButton.defaultProps = {
  color: "secondary",
  size: "md",
  block: false,
  outline: false,
};

export default SignInWithEthereumButton;
