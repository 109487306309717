import React from "react";
import PropTypes from "prop-types";

/**
 * Table container used as app default landing page
 */
const BlockExplorerLink = (props) => {
  const {
    address,
    network,
    children,
    className,
    title,
    tokenIdentifier,
    transactionHash,
  } = props;

  let targetUrl;
  switch (network) {
    case "etherium":
      targetUrl = "https://etherscan.io";
      break;
    case "rinkeby":
      targetUrl = "https://rinkeby.etherscan.io";
      break;
    case "goerli":
      targetUrl = "https://goerli.etherscan.io";
      break;
    case "kovan":
      targetUrl = "https://kovan.etherscan.io";
      break;
    case "ropsten":
      targetUrl = "https://ropsten.etherscan.io";
      break;
    case "polygon":
      targetUrl = "https://polygonscan.com";
      break;
    default:
      targetUrl = "https://etherscan.io";
  }

  if (address && tokenIdentifier) {
    targetUrl = `${targetUrl}/token/${address}?a=${tokenIdentifier}`;
  } else if (transactionHash) {
    targetUrl = `${targetUrl}/tx/${transactionHash}`;
  } else if (address) {
    targetUrl = `${targetUrl}/address/${address}`;
  } else {
    targetUrl = `${targetUrl}`;
  }

  return (
    <a
      href={targetUrl}
      target="_blank"
      rel="noreferrer"
      className={className}
      title={title}
    >
      {children}
    </a>
  );
};

/**  define proptypes for BlockExplorerLink  */
BlockExplorerLink.propTypes = {
  address: PropTypes.string,
  network: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  tokenIdentifier: PropTypes.string,
  transactionHash: PropTypes.string,
};

/**  define defaultProps for BlockExplorerLink  */
BlockExplorerLink.defaultProps = {
  network: "etherium",
  className: null,
  title: null,
  tokenIdentifier: null,
  transactionHash: null,
};

/** export the component as redux connected component */
export default BlockExplorerLink;
