import { Connector } from "@web3-react/types";
import {
  loadConnectKit,
  SupportedProviders,
} from "./ledger-connect-kit";
// import { ethers } from "ethers";

function parseChainId(chainId) {
  return typeof chainId === "string" ? Number.parseInt(chainId, 16) : chainId;
}

/**
 * @param provider - An EIP-1193 ({@link https://github.com/ethereum/EIPs/blob/master/EIPS/eip-1193.md}) provider.
 * @param onError - Handler to report errors thrown from eventListeners.
 */

export class LedgerWallet extends Connector {
  constructor({ actions, options, onError }) {
    super(actions, onError);
    this.chainId = options.chainId;
    this.chainHex = options.chainHex;
    this.rpc = options.rpc;

    this.connectKitPromise = loadConnectKit();
    // this.provider = provider;
  }

  /** {@inheritdoc Connector.connectEagerly} */
  async connectEagerly() {
    const cancelActivation = this.actions.startActivation();

    try {
      return Promise.all([
        this.provider.request({ method: "eth_chainId" }),
        this.provider.request({ method: "eth_accounts" }),
      ])
        .then(([chainId, accounts]) => {
          this.actions.update({ chainId: parseChainId(chainId), accounts });
        })
        .catch((error) => {
          cancelActivation();
          this.actions.resetState();
          throw error;
        });
    } catch (error) {
      cancelActivation();
      this.actions.resetState();
      throw error;
    }
  }

  /** {@inheritdoc Connector.activate} */
  async activate() {
    const cancelActivation = this.actions.startActivation();

    // if (!this.web3auth) {
    //   await this.startWeb3Auth();
    // } else if (this.web3auth.status === "ready") {
    //   await this.startAdapter();
    // }
    // await this.startProvider();
    const isServer = !(
      typeof window !== "undefined" &&
      window.document &&
      window.document.createElement
    );
    if (isServer) return;
    if (!this.provider) {
      console.log("rpc, ", this.rpc);
      // load Connect Kit, check support and show a UI modal if not supported
      const connectKit = await this.connectKitPromise;
      const checkSupportResult = connectKit.checkSupport({
        chainId: this.chainId,
        providerType: SupportedProviders.Ethereum,
        rpc: this.rpc,
      });
      console.log("checkSupportResult is", checkSupportResult);

      this.provider = await connectKit.getProvider();
      console.log("provider is ", this.provider);
    }

    console.log("before promise provider is ", this.provider);
    return Promise.all([
      this.provider.request({ method: "eth_chainId" }),
      this.provider
        .request({ method: "eth_requestAccounts" })
        .catch(() => this.provider.request({ method: "eth_accounts" })),
    ])
      .then(([chainId, accounts]) => {
        console.log("chainId, accounts", chainId, accounts);
        this.actions.update({ chainId: parseChainId(chainId), accounts });
      })
      .catch((error) => {
        cancelActivation();
        throw error;
      });
  }

  async deactivate() {
    console.log("deactivate");
    // if (this.web3auth) {
    //   await this.web3auth.logout();
    //   this.web3auth = undefined;
    // }
    if (this.provider) {
      // this.provider.removeAllListeners();
      this.provider = undefined;
    }

    this.actions.resetState();
  }
}
