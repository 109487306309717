/*
Replace
nft with your resource Name :) Take care about different writings!
/!\ Nft nft Nfts nfts /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const walletProfileInputsSlice = createSlice({
  name: "walletProfileInputs",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    updateErrorReset: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
    listErrorReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createErrorReset: (state, action) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
    showErrorReset: (state, action) => {
      state.showing = false;
      state.showed = false;
      state.showError = null;
      state.unlockError = null;
      state.unlockSuccess = false;
    },
  },
});

export const {
  show: showWalletProfileInput,
  update: updateWalletProfileInput,
  list: listWalletProfileInputs,
  create: createWalletProfileInput,
  updateErrorReset: updateWalletProfileInputErrorReset,
  listErrorReset: listWalletProfileInputErrorReset,
  createErrorReset: createWalletProfileInputErrorReset,
  showErrorReset: showWalletProfileInputErrorReset,
} = walletProfileInputsSlice.actions;
export const actions = walletProfileInputsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const walletProfileInputs = (state) =>
  state.walletProfileInputs.idsList.map(
    (id) => state.walletProfileInputs.dictionary[id]
  );
export const walletProfileInput = (state) =>
  state.walletProfileInputs.showId &&
  state.walletProfileInputs.dictionary[state.walletProfileInputs.showId];
export const walletProfileInputAttributes = (state) =>
  walletProfileInput(state)?.attributes;
export const walletProfileInputRelationships = (state) =>
  walletProfileInput(state)?.relationships;
export const walletProfileInputId = (state) => state.walletProfileInputs.showId;

export const getWalletProfileInput = createSelector(
  (state) => state.walletProfileInputs.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getWalletProfileInputAttributes = createSelector(
  (state) => state.walletProfileInputs.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getWalletProfileInputRelationships = createSelector(
  (state) => state.walletProfileInputs.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getWalletProfileInputs = createSelector(
  (state) => state.walletProfileInputs.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export const getWalletProfileInputFormData = createSelector(
  (state) => state.walletProfileInputs.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    let formData = id && dictionary ? { ...dictionary[id]?.attributes } : {};
    let inputTypeDefaultValue = {
      value: formData?.input_type,
      label: formData?.input_type,
    };
    formData.input_type = inputTypeDefaultValue;
    return formData;
  }
);

export default walletProfileInputsSlice.reducer;
