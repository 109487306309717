/**
 * The Page component
 */
import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import logo from "@bkry/bowline-components/assets/images/logo.png";
import { useLocation } from "react-router-dom";

import { env } from "@bkry/bowline-utils";
const {
  REACT_APP_WEB_SERVER,
  REACT_APP_FB_ID,
  REACT_APP_CDN_PATH,
  REACT_APP_GTM,
} = env;
/** Default values constants */
const defaultTitle = "Bowline";
const defaultDescription =
  "Extend the capabilities of your NFT Collection and create an engaging community with exclusive features for your holders. Grow your secondary sales with extensive analytics on token owners, whitelisted wallets and market data.";
const defaultImage = `${REACT_APP_WEB_SERVER}${logo}`;
const defaultTwitter = "@bowlineapp";
const defaultSep = " | ";

/**
 * Page Component
 * - sets SEO data for each page
 * - used to wrap routes
 */
const Page = (props) => {
  const location = useLocation();
  /** generate Meta tags */
  const getMetaTags = (
    {
      title,
      description,
      image,
      contentType,
      twitter,
      noCrawl,
      published,
      updated,
      category,
      tags,
    },
    pathname
  ) => {
    const theTitle = title
      ? (title + defaultSep + defaultTitle).substring(0, 60)
      : defaultTitle;
    const theDescription = description
      ? description.substring(0, 155)
      : defaultDescription;
    const theImage = image ? image : defaultImage;

    const metaTags = [
      { itemprop: "name", content: theTitle },
      { itemprop: "description", content: theDescription },
      { itemprop: "image", content: theImage },
      { name: "apple-mobile-web-app-title", content: "bowline" },
      { name: "application-name", content: "bowline" },
      { name: "description", content: theDescription },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:site", content: defaultTwitter },
      { name: "twitter:title", content: theTitle },
      { name: "twitter:description", content: theDescription },
      { name: "twitter:creator", content: twitter || defaultTwitter },
      { name: "twitter:image:src", content: theImage },
      { property: "og:title", content: theTitle },
      { property: "og:type", content: contentType || "website" },
      { property: "og:url", content: REACT_APP_WEB_SERVER + pathname },
      { property: "og:image", content: theImage },
      { property: "og:description", content: theDescription },
      { property: "og:site_name", content: defaultTitle },
      { property: "fb:app_id", content: REACT_APP_FB_ID },
      { name: "msapplication-TileColor", content: "#363a3f" },
      { name: "theme-color", content: "#363a3f" },
    ];

    if (noCrawl) {
      metaTags.push({ name: "robots", content: "noindex, nofollow" });
    }
    if (published) {
      metaTags.push({ name: "article:published_time", content: published });
    }
    if (updated) {
      metaTags.push({ name: "article:modified_time", content: updated });
    }
    if (category) {
      metaTags.push({ name: "article:section", content: category });
    }
    if (tags) {
      metaTags.push({ name: "article:tag", content: tags });
    }
    metaTags.push({
      name: "msapplication-config",
      content: REACT_APP_CDN_PATH
        ? REACT_APP_CDN_PATH
        : "" + `/favicon/browserconfig.xml`,
    });
    return metaTags;
  };

  /** generate Links */
  const getLinkTags = (pathname) => {
    const linkTags = [
      { rel: "canonical", href: REACT_APP_WEB_SERVER + pathname },
      // { rel: 'alternate', hreflang: 'en', href: REACT_APP_WEB_SERVER +  '/en/' },
      // { rel: 'alternate', hreflang: 'de', href: REACT_APP_WEB_SERVER +  '/de/' },
      // { rel: 'alternate', hreflang: 'es', href: REACT_APP_WEB_SERVER +  '/es/' },
      // { rel: 'alternate', hreflang: 'it', href: REACT_APP_WEB_SERVER +  '/it/' },
      // { rel: 'alternate', hreflang: 'x-default', href: REACT_APP_WEB_SERVER + '/en/' },
      {
        rel: "apple-touch-icon",
        sizes: "180x180",
        href: REACT_APP_CDN_PATH
          ? REACT_APP_CDN_PATH
          : "" + `/favicon/apple-touch-icon.png`,
      },
      {
        rel: "icon",
        type: "image/png",
        sizes: "32x32",
        href: REACT_APP_CDN_PATH
          ? REACT_APP_CDN_PATH
          : "" + `/favicon/favicon-32x32.png`,
      },
      {
        rel: "icon",
        type: "image/png",
        sizes: "16x16",
        href: REACT_APP_CDN_PATH
          ? REACT_APP_CDN_PATH
          : "" + `/favicon/favicon-16x16.png`,
      },
      {
        rel: "manifest",
        href: REACT_APP_CDN_PATH ? REACT_APP_CDN_PATH : "" + `/manifest.json`,
      },
      {
        rel: "mask-icon",
        color: "#363a3f",
        href: REACT_APP_CDN_PATH
          ? REACT_APP_CDN_PATH
          : "" + `/favicon/safari-pinned-tab.svg`,
      },
      {
        rel: "shortcut icon",
        href: REACT_APP_CDN_PATH
          ? REACT_APP_CDN_PATH
          : "" + `/favicon/favicon.ico`,
      },
    ];
    return linkTags;
  };

  /** generate Links */
  const getJsonLDdata = (
    {
      title,
      description,
      // image,
      // contentType,
      // twitter,
      // noCrawl,
      // published,
      // updated,
      // category,
      // tags,
    },
    pathname
  ) => {
    const theTitle = title
      ? (title + defaultSep + defaultTitle).substring(0, 60)
      : defaultTitle;
    const theDescription = description
      ? description.substring(0, 155)
      : defaultDescription;

    const jsonLd = {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: REACT_APP_WEB_SERVER + pathname,
      name: theTitle,
      description: theDescription,
      keywords: "",
      publisher: {
        "@type": "Organization",
        name: "organization name",
      },
    };
    return jsonLd;
  };

  const getScriptTags = (rest, pathname) => {
    let scripts = [];
    // add JsonLDData
    scripts.push({
      type: "application/ld+json",
      innerHTML: JSON.stringify(getJsonLDdata(rest, pathname)),
    });
    // create datalayer
    scripts.push({
      innerHTML: "window.dataLayer = window.dataLayer || [];",
    });
    // add tag manager
    if (REACT_APP_GTM)
      scripts.push({
        innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${REACT_APP_GTM}');`,
      });

    return scripts;
  };

  // const getNoScriptTags = (rest, pathname) => {
  //   let noscripts = []
  //   // add tag manager

  //   // if (REACT_APP_GTM)
  //   //   noscripts.push({
  //   //     innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${REACT_APP_GTM}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
  //   //   })
  //   return noscripts;
  // }

  /** render the meta information in the headers */
  const { children, id, className, ...rest } = props;
  const noScriptGTM = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${REACT_APP_GTM}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;
  return (
    <div id={id} className={className}>
      <Helmet
        htmlAttributes={{
          lang: rest.lang || "en",
          itemscope: undefined,
          itemtype: `http://schema.org/${rest.schema || "WebPage"}`,
        }}
        title={
          rest.title ? rest.title + defaultSep + defaultTitle : defaultTitle
        }
        link={getLinkTags(location.pathname)}
        meta={getMetaTags(rest, location.pathname)}
        script={getScriptTags(rest, location.pathname)}
      ></Helmet>
      {REACT_APP_GTM && (
        <div dangerouslySetInnerHTML={{ __html: noScriptGTM }} />
      )}

      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  className: PropTypes.string,
};

Page.defaultProps = {};

export default Page;
