import { initializeConnector } from "@web3-react/core";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { env } from "@bkry/bowline-utils";
const { REACT_APP_INFURA_ID, REACT_APP_MAINNET } = env;

export const [coinBase, hooks] = initializeConnector(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url:
          "https://" +
          (REACT_APP_MAINNET ? "mainnet" : "goerli") +
          ".infura.io/v3/" +
          REACT_APP_INFURA_ID,
      },
    })
);
