/**
 * The FooterLayout container
 */
import { BigFooter, SlimFooter } from "@bkry/bowline-components/Footers";
import { getProjectAttributes } from "@bkry/bowline-redux/projects";
import { env } from "@bkry/bowline-utils";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

const { REACT_APP_PROJECT_ID } = env;

/**
 * FooterLayout component used as app default landing page
 *
 */

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */

const FooterLayout = () => {
  const projectData = useSelector((state) =>
    getProjectAttributes(state, REACT_APP_PROJECT_ID)
  );

  return (
    <div className="footer">
      {projectData?.footer_layout === "slim" ? (
        <SlimFooter />
      ) : projectData?.footer_layout === "big" ? (
        <BigFooter />
      ) : null}
    </div>
  );
};

/**  define proptype for the 'translation' function  */
FooterLayout.contextTypes = {
  t: PropTypes.func,
};

FooterLayout.propTypes = {};

/** export the component as redux connected component */
export default FooterLayout;
