/**
 * The Home container
 */
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import {
  getTraitAttributes,
  getTraitRelationships,
} from "@bkry/bowline-redux/traits";
import { getTraitTypeAttributes } from "@bkry/bowline-redux/traitTypes";

/**
 * Home container used as app default landing page
 */
const TokenProperty = (props) => {
  const { traitId } = props;
  const traitData = useSelector((state) => getTraitAttributes(state, traitId));
  const traitRelationships = useSelector((state) =>
    getTraitRelationships(state, traitId)
  );

  const traitType = useSelector((state) =>
    getTraitTypeAttributes(state, traitRelationships?.trait_type?.data?.id)
  );

  return (
    <Card className="tokenproperty h-100">
      <CardBody className="d-flex flex-column align-items-center justify-content-center">
        {traitType.name && (
          <span className="text-center text-break">{traitType.name}</span>
        )}
        {traitData.value && (
          <span className="fw-bold text-center text-break">
            {traitData.value}
          </span>
        )}
        {traitType.display_type && (
          <span className="text-center text-break text-muted small">
            {traitType.display_type}
          </span>
        )}
      </CardBody>
    </Card>
  );
};

TokenProperty.propTypes = {
  traitId: PropTypes.string.isRequired,
};

/**  define proptype for the 'translation' function  */
TokenProperty.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default TokenProperty;
